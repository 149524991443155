import Vue from 'vue'
import VueRouter from 'vue-router'
import configRouter from '@/router/configRouter'
import { sentryLog } from '@/utils/sentry'
import SelfLogIns from '@/utils/selfLog'
import { handleSpecialPageTime } from '@/utils/common'

const testPage = [
	{
		path: '/test',
		name: 'test',
		component: () => import(/* webpackChunkName: "test" */ '@/views/test/index'),
		meta: {
			title: 'test',
		},
	},
]
// const devTestArray = process.env.VUE_APP_ONLINE_ENV === 'true' ? [] : [...testPage]
const devTestArray = [...testPage]
Vue.use(VueRouter)
window.debuglog = function() {
	if (process.env.VUE_APP_ONLINE_ENV === 'false') {
		console.log(...arguments) // eslint-disable-line
	}
}
// 解决如果路由地址跳转相同, 且没有捕获到错误，控制台始终会出现
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function push(location) {
	return originalReplace.call(this, location).catch((err) => err)
}

const routes = [
	...devTestArray,
	...configRouter,
	{
		path: '/',
		redirect: '/test',
	},
	{
		path: '/empty/:url',
		component: () => import('@/views/home/empty'),
		meta: {
			title: '详情',
		},
	},
	{
		path: '/home/index',
		name: 'home',
		component: () => import(/* webpackChunkName: "login" */ '@/views/home/index'),
		meta: {
			title: '首页',
		},
	},
	{
		path: '/login',
		name: 'login',
		component: () => import(/* webpackChunkName: "login" */ '@/views/login'),
		meta: {
			title: '登录',
		},
	},
	{
		path: '/error500',
		name: 'Error500',
		component: () => import(/* webpackChunkName: "Error404" */ '@/views/error/error500'),
		meta: {
			title: '500',
		},
	},
	{
		path: '*',
		name: 'Error404',
		component: () => import(/* webpackChunkName: "Error404" */ '@/views/error/error404'),
		meta: {
			title: '404',
		},
	},
	{
		path: '/down-load',
		name: 'download',
		component: () => import('@/views/home/down-load'),
		meta: {
			title: '坤元基金丨获取丰富金融信息',
		},
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.VUE_APP_PUBLIC_PATH,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
})

router.beforeEach(async (to, from, next) => {
  const { name } = to
  const specialRouterNameMap = [
    'productIndex'
  ]
  if (specialRouterNameMap.includes(name)) {
    handleSpecialPageTime('set', name, Date.now())
  }
  next()
})

router.afterEach(async (to) => {
	// 重置页面title
	document.title = to.meta && to.meta.title ? to.meta.title : '坤元基金H5'
	// 设置背景色
	if (to.meta.bodyBgColor) {
		document.body.style.backgroundColor = to.meta.bodyBgColor
	} else {
		document.body.style.backgroundColor = null
	}

  const { name } = to
  sentryLog('pv', name)
  SelfLogIns.pv(name)
})

export default router
