import * as Sentry from '@sentry/vue'
import { isApp, handleDeviceId, getUUID, sendDeviceIdMessage } from '@/utils/common'
import { sentryLog } from '@/utils/sentry'

/**
 * 初始化sentry
 * @param app
 * @param router
 */
const sentryInject = (app, router) => {
  if (isApp()) {
    Sentry.setUser({
      username: getUUID()
    })

    Sentry.init({
      app,
      dsn: 'https://5655386c5b0d430f90256695f7ea49a2@sentry.kunyuanfund.com/12',
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        })
      ],
      sampleRate: 0.1,
      tracesSampleRate: 0.1,
      environment: process.env.NODE_ENV,
      tracePropagationTargets: ['localhost', /^\//]
    })
  }
}

// 程序首次加载上报init点
const InitProjectEvent = () => {
  // 全局注册成功回调函数
  window.getDeviceIdCb = (res) => {
    const parseData = typeof res === 'string' ? JSON.parse(res) : res
    const { deviceId = '' } = parseData

    handleDeviceId('set', deviceId)
    setUserAndSendLog(deviceId)
  }

  // 发送获取device事件
  sendDeviceIdMessage()

  const setUserAndSendLog = (deviceId) => {
    Sentry.setUser({
      id: deviceId,
      username: getUUID()
    })
    sentryLog('init')
  }
}

const sentryInit = (app, router) => {
  sentryInject(app, router)
  InitProjectEvent()
}

export {
  sentryInit
}
