<template>
  <div class="loading" v-show="isShow">
    <md-toast ref="toast" hasMask>
      <md-activity-indicator
        type="spinner"
        :size="20"
        color="light"
        :text-size="15"
        text-color="white"
      >
        loading...
      </md-activity-indicator>
    </md-toast>
  </div>
</template>

<script>
import { Toast } from 'mand-mobile'
export default {
  name: 'loading',
  data() {
    return {
      isShow: false,
    }
  },
  components: {
    [Toast.component.name]: Toast.component,
  },
  methods: {
    open() {
      this.$refs.toast.show()
      this.isShow = true
    },
    close() {
      this.$refs.toast.hide()
      this.isShow = false
    },
  },
}
</script>

<style scoped lang="stylus"></style>
