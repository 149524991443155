export default {
  name: 'kyjj-home',
  path: '/home',
  component: () =>
    import(/* webpackChunkName: "homeMain" */ '@/views/layouts/layoutHome'),
  redirect: '/home/template',
  children: [
    {
      path: 'template',
      name: 'home-template',
      component: () =>
        import(/* webpackChunkName: "homeMain" */ '@/views/main/template'),
      meta: {
        title: '模板',
      },
    },
  ],
}
