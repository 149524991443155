/* eslint-disable */
export const SUCCESS_CODE = '000000'
export const V1_SUCCESS_CODE = '200'
import router from '@/router'
import { v4 as uuidv4 } from "uuid";

/**
 * ios 调用app的方法，不返回值
 * @param params
 * @returns {Promise<any>}
 */
export const sendAppMessage = (params = {}) => {
	return new Promise((resolve, reject) => {
		setupWebViewJavascriptBridge(function (bridge) {
			if (isAndroid()) {
				try {
					bridge.init(function (message, responseCallback) { })
				} catch (e) { }
			}
			bridge.callHandler(params.action, params, () => {
				resolve('交互成功')
			})
		})
	})
}
/**
 * ios 调用 app 的方法返回值
 * @param params
 * @returns {Promise<any>}
 */
export const getAppMessage = (params = {}) => {
	return new Promise((resolve, reject) => {
		setupWebViewJavascriptBridge(function (bridge) {
			if (isAndroid()) {
				try {
					bridge.init(function (message, responseCallback) { })
				} catch (e) { }
			}
			bridge.callHandler(params.action, params, (response) => {
				resolve(response)
			})
		})
	})
}

export const registerAppMethod = (params = {}, callback) => {
	return new Promise((resolve) => {
		setupWebViewJavascriptBridge(function (bridge) {
			if (isAndroid()) {
				try {
					bridge.init(function (message, responseCallback) { })
				} catch (e) { }
			}
			bridge.registerHandler(params.action, (data, responseCallback) => {
				callback && callback(data)
				responseCallback({ from: 'js', data: 'js的数据' })
			})
			resolve('交互成功')
		})
	})
}

// 金额千分位处理
export function formatMoney(num) {
	const localnum = typeof num === 'string' ? num : num.toString()
	return localnum.indexOf(',') !== -1
		? num
		: parseFloat(num)
			.toFixed(2)
			.replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
}
// window.self !== window.top false 时候代表是在 iframe 中
export function isApp() {
	return Boolean(window.webkit)
}

export function isAndroid() {
	// 处理特殊情况
	const u = navigator.userAgent
	return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
}
// 微信版本是否大于6.3.31
export function weixinVersionEnoughHigh() {
	var str = window.navigator.userAgent
	var v0 = [6, 3, 31]
	var regExp = /MicroMessenger\/([.\d]+)/
	if (regExp.exec(str) === null) {
		return
	}
	var v1 = regExp.exec(str)[1].split('.')
	if (v1.length >= 4) {
		v1 = v1.slice(0, 3)
	}
	v1 = v1.map(function (v) {
		return parseInt(v, 10)
	})
	if (v1[0] > v0[0]) {
		return true
	}
	if (v1[0] === v0[0] && v1[1] > v0[1]) {
		return true
	}
	if (v1[0] === v0[0] && v1[1] === v0[1] && v1[2] >= v0[2]) {
		return true
	}
	return false
}
// 根据身份证提取生日
export function byCardGetBirthday(id) {
	if (/^[0-9]{15}$/.test(id)) {
		return `19${id.substr(6, 2)}-${id.substr(8, 2)}-${id.substr(10, 2)}`
	}
	if (/^[0-9]{17}[0-9xX]$/.test(id)) {
		return `${id.substr(6, 4)}-${id.substr(10, 2)}-${id.substr(12, 2)}`
	}
}
export function formatPhone(value) {
	if (!value) return ''
	return value.replace(/(1\w{2})(\S{4})(\w{4})/g, '$1 $2 $3')
}
export function dealNumberUnit(str) {
	if (!str) {
		return {}
	}
	str.match(/([1-9][0-9]*([\.][0-9]{1,2})?)([\u4E00-\u9FA5]*)/g)
	let number = RegExp.$1 // 数字
	let unit = RegExp.$3 // 单位
	return {
		number,
		unit,
	}
}

/**
 * 判读当前的宿主环境是不是在微信中,包含企业微信
 * @returns {boolean}
 */
export function isWeixin() {
	const ua = navigator.userAgent.toLowerCase()
	return ua.indexOf('micromessenger') !== -1
}

/**
 * 判断当前的宿主环境只在微信中不在企业微信中
 * @returns {boolean}
 */
export function isWeixinPublic() {
	const ua = navigator.userAgent.toLowerCase()
	return ua.indexOf('micromessenger') !== -1 && !ua.match(/WxWork/i)
}
/**
 * 处理接口返回的路径（去掉域名和项目名）
 */
export function dealLinkUrl(path) {
	if (!path) return
	const target = process.env.VUE_APP_H5_TARGET
	if (path.startsWith(target)) {
		//如果包含 使用$router进行路由跳转
		router.push({
			path: path.replace(target, '').replace(/\s+/, ''),
		})
	} else {
		window.location.href = path
	}
}
export const regexpCommon = {
	// 验证码
	code: /^\d{6}$/,
	// 手机
	phone: /^1[3-9]\d{9}$/,
	// 身份证
	idCard: /(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
	// 银行卡
	bankCard: /^\d{14,19}$/,
	// 中文姓名包含少数民族
	userName: /^[\u4e00-\u9fa5]+((·|•)[\u4e00-\u9fa5]+)*$/,
	// 微信号
	wechat: /^[-_a-zA-Z0-9]{6,20}$/,
	// 座机号码
	seatNumber: /0\d{2,3}-\d{7,8}/,
	//过滤表情
	emoticonPicture: /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030|\s/gi,
}
export const defaultImg = {
	defaultAvatar: `${process.env.VUE_APP_PUBLIC_PATH === '/' ? '' : process.env.VUE_APP_PUBLIC_PATH
		}/img/placeholder-user.png`,
	defaultShareIcon: `${process.env.VUE_APP_PUBLIC_PATH === '/' ? '' : process.env.VUE_APP_PUBLIC_PATH
		}/img/logo_xiaoheng.png`,
}
/**
 * 返回链接类型
 * @param linkString
 * @returns {string}
 */
export function judgeFileType(linkString) {
	if (typeof linkString !== 'string') {
		return 'notString'
	}
	if (!linkString.match(/^https?:\/\/([^\/]+)/)) {
		return 'notLink'
	}
	const needString = linkString.split('?')[0]
	if (needString.match(/.*(\.pdf|\.hc)$/)) {
		return 'isPdf'
	}
	if (needString.match(/.*(\.png|\.jpg|\.jpeg|\.bmp|\.gif)$/)) {
		return 'isImg'
	}
	return 'otherLink'
}

/**
 * 查看文件
 * @param linkString
 * @param name
 */
export async function checkFile(linkString, name = null) {
	const type = judgeFileType(linkString)
	if (type === 'isPdf' || type === 'isHc') {
		/* if (isApp()) {
			await sendAppMessage({
				action: 'defaultBrowserOpen',
				url: linkString,
			})
			return
		} */
		router.push({
			path: '/preview/pdf',
			query: {
				url: linkString,
				name,
			},
		})
	}
	if (type === 'isImg') {
		router.push({
			path: '/preview/img',
			query: {
				url: linkString,
				name: name || '查看图片',
			},
		})
	}
	if (type === 'otherLink') {
		window.location.href = linkString
	}
}

// 尺寸改变事件以及响应事件时间
export const resizeEvent = typeof window.orientation === 'number' ? 'orientationchange' : 'resize'
export const resizeTime = 300

//风险标签 bgEn:英文标签背景色 bgCn：中文标签背景色 cnBorderColor:中文标签边框色
export const riskTag = {
	M1: {
		bgEn: '#B96D40',
		bgCn: '#FFEEDE',
		cnBorderColor: 'rgba(190, 116, 73, 0.2)',
		textEn: 'R1',
		textCn: '募集',
	},
	R1: {
		bgEn: '#4FC548',
		bgCn: 'rgba(43,195,34,0.09)',
		cnBorderColor: 'rgba(43, 195, 34, 0.36)',
		textEn: 'R1',
		textCn: '低风险',
	},
	R2: {
		bgEn: '#22BBC3',
		bgCn: 'rgba(14,161,148,0.08)',
		cnBorderColor: 'rgba(14,161,148,0.23)',
		textEn: 'R2',
		textCn: '中低风险',
	},
	R3: {
		bgEn: '#F5A623',
		bgCn: 'rgba(255,201,110,0.17)',
		cnBorderColor: 'rgba(245,166,35,1)',
		textEn: 'R3',
		textCn: '中风险',
	},
	R4: {
		bgEn: '#FF8B50',
		bgCn: 'rgba(254,239,234,1)',
		cnBorderColor: 'rgba(255,150,97,0.4)',
		textEn: 'R4',
		textCn: '中高风险',
	},
	R5: {
		// bgEn: '#EF4034',
		bgEn: '#FF5051',
		bgCn: 'rgba(254,239,234,1)',
		cnBorderColor: 'rgba(255,80,81,0.18)',
		textEn: 'R5',
		textCn: '高风险',
	},
}
export const JZ_TOKEN = 'jz-token'

// 获取css样式
export function getHeight(element, attr) {
	if (window.getComputedStyle) {
		return attr ? window.getComputedStyle(element, null)[attr] : window.getComputedStyle(element, null)
	}
	return attr ? element.currentStyle[attr] : element.currentStyle
}

function androidAppMessage(type, jumpUrl, flag) {
	const androidObj = {
		closeWebpage: 'closeCurrentWebpage',
	}
	type = androidObj[type] || type
	console.log('android', type)
	if (type === 'setStatusBarStyleLight') {
		window.webkit[type](jumpUrl)
		return
	}
	if (!jumpUrl) {
		window.webkit[type]()
		return
	}
	javascript: webkit[type](jumpUrl, flag)
}

function iosAppMessage(type, jumpUrl, flag) {
	console.log('ios', type)
	if (type === 'openNewPage') {
		javascript: webkit.messageHandlers[type].postMessage({
			url: jumpUrl,
		})
		return
	}
	if (type === 'setStatusBarStyleLight') {
		window.webkit.messageHandlers[type].postMessage(jumpUrl)
		return
	}
	flag = type === 'jumpTo' ? Number(flag) : flag
	javascript: webkit.messageHandlers[type].postMessage({
		route: jumpUrl,
		flag: flag,
	})
}

export function newPostAppMessage(type, paramsObj, successCbName) {
  if (isApp()) {
    const data = {
      name: type,
      data: paramsObj,
      successCbName: successCbName
    }
    if (isAndroid()) {
      window.webkit && window.webkit[type] && window.webkit[type](JSON.stringify(data))
    } else {
      webkit.messageHandlers && webkit.messageHandlers[type] && webkit.messageHandlers[type].postMessage(data)
    }
  }
}

export function postNewAppMessage(type, jumpUrl, flag, failCallback) {
	try {
		if (isApp()) {
			if (isAndroid()) {
				androidAppMessage(type, jumpUrl, flag)
			} else {
				iosAppMessage(type, jumpUrl, flag)
			}
			return
		}
		failCallback && failCallback()
	} catch (error) {
		console.log("postNewAppMessage", error)
	}
}

export function postAppMessage(jumpUrl, flag, failCallback) {
	try {
		if (isApp()) {
			if (isAndroid()) {
				javascript: webkit.jumpTo(jumpUrl, flag)
				return
			}
			javascript: webkit.messageHandlers.jumpTo.postMessage({
				route: jumpUrl,
				flag: flag,
			})
			return
		}
		failCallback && failCallback()
	} catch (error) {
		console.log("postAppMessage", error)
	}
}

export function jumpApp(jumpUrl, flag, failCallback) {
	try {
		if (window.webkit) {
			if (isAndroid()) {
				javascript: webkit.jumpTo(jumpUrl, flag)
				return
			}
			javascript: webkit.messageHandlers.jumpTo.postMessage({
				route: jumpUrl,
				flag: flag,
			})
			return
		}
		failCallback && failCallback()
	} catch (error) {
		console.log(error)
	}
}

export function showErrorInfo(that, msg) {
	that.$toast.info(msg.toString())
}

export function showLoading(that, msg = 'loading...') {
	that.$toast.loading(msg)
}

export function hideLoading(that) {
	that.$toast.hide()
}

export function getSafeAreaLength(safeArea) {
	if (!safeArea) return 0
	const ua = navigator.userAgent.split(' ')
	const data = ua.filter((res) => {
		return res.indexOf(safeArea) > -1
	})
	if (!data || !data.length) return 15
	const percent = data[0] && data[0].split('=')[1]
	const screenHeight = screen.height
	return Number(percent) * screenHeight || 15
}

export function getUUID () {
  const idKey = '__uuid'
  const catchUUID = localStorage.getItem(idKey)
  if (catchUUID) {
    return catchUUID
  } else {
    const uuid = uuidv4()
    localStorage.setItem(idKey, uuid)
    return uuid
  }
}

export function handleDeviceId (type,  value) {
  const idKey = '__deviceId'
  if (type === 'get') {
    return localStorage.getItem(idKey)
  } else {
    localStorage.setItem(idKey, value)
    return value
  }
}

export function sendDeviceIdMessage () {
  newPostAppMessage('getDeviceId', {}, 'getDeviceIdCb')
}

export function sendDotMessageToApp (params) {
  // newPostAppMessage('statisticsEvent', {}, 'statisticsEventCallback')
  // [note] 特殊情况没使用公共的方法，因为ios发版后解析接收数据层级少了一层，所以h5本次特殊兼容，ios下次发版修复后，必须切回到使用公共方法的action调用
  if (isApp()) {
    const type = 'statisticsEvent'
    const data = {
      name: type,
      data: params,
      successCbName: 'statisticsEventCallback'
    }
    if (isAndroid()) {
      window.webkit && window.webkit[type] && window.webkit[type](JSON.stringify(data))
    } else {
      window.webkit.messageHandlers && window.webkit.messageHandlers[type] && window.webkit.messageHandlers[type].postMessage(params)
    }
  }
}

/**
 * 处理页面特殊加载时间
 * @param type  get | set
 * @param key   要获取的map的key
 * @param value 要设置的map的value
 * @returns {*|undefined}
 */
export function handleSpecialPageTime (type,  key, value) {
  if (!type || !key) throw new Error('请检查传入参数!')

  const idKey = '__specialPageTime'
  const storageMap = JSON.parse(sessionStorage.getItem(idKey) || '{}')
  if (type === 'get') {
    return storageMap[key]
  } else {
    storageMap[key] = value
    sessionStorage.setItem(idKey, JSON.stringify(storageMap))
    return storageMap
  }
}
