<!-- NoData -->
<template>
  <div class="no-more-data" @click="getMoreData">
    {{isReachBottom?"已全部加载":"点我加载更多···"}}
  </div>
</template>

<script>
export default {
  name: 'noMoreData',
  props: {
    isReachBottom: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    getMoreData () {
      const { isReachBottom } = this
      if (!isReachBottom) {
        this.$emit('getMoreData')
      }
    }
  }
}

</script>
<style lang='stylus' scoped>
  .no-more-data{
    font-size :font-body-normal;
    line-height :1;
    color :color-text-minor;
    cursor :pointer;
    padding :20px 0;
  }
</style>
