export default {
	name: 'preserve',
	path: '/preserve',
	component: () => import(/* webpackChunkName: "mine" */ '@/views/layouts/blank.vue'),
	children: [
		{
			path: 'index',
			name: 'preserveIndex',
			component: () => import(/* webpackChunkName: "preserveIndex" */ '@/views/preserve/index'),
			meta: {
				title: '系统升级中…',
			},
		},
		{
			path: 'notice',
			name: 'notice',
			component: () => import(/* webpackChunkName: "preserveNotice" */ '@/views/preserve/notice'),
			meta: {
				title: '升级公告',
			},
		},
	],
}
