export default {
	name: 'preview',
	path: '/preview',
	component: () => import(/* webpackChunkName: "preview" */ '@/views/layouts/blank.vue'),
	children: [
		{
			path: 'pdf',
			name: 'pdf',
			component: () => import(/* webpackChunkName: "previewPdf" */ '@/views/preview/previewPdf'),
			meta: {
				title: '文件预览',
			},
		},
		{
			path: 'img',
			name: 'img',
			component: () => import(/* webpackChunkName: "previewImg" */ '@/views/preview/previewImg'),
			meta: {
				title: '图片预览',
			},
		},
	],
}
