// 自定义指令文件
import Vue from 'vue'

function resetPage() {
	const u = navigator.userAgent
	var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
	if (isIOS) {
		setTimeout(function() {
			const scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0
			window.scrollTo(0, Math.max(scrollHeight - 1, 0))
		}, 200)
	}
}

Vue.directive('resetPage', {
	inserted: (el) => {
		console.log('inserted')
		el.addEventListener('focusout', resetPage)
	},
	unbind: (el) => {
		console.log('unbind')
		el.removeEventListener('focusout', resetPage)
	},
})
