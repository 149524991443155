import { isApp, sendDotMessageToApp } from "./common";

/**
 * log埋点上报
 */
class SelfLog {
    // 模块名称
    moduleName = 'pe_'
    // 埋点不同类型对应类型码
    dotTypeMap = {
      'PV': 'B10000007',
      'Perf': 'B10000008'
    }
    static timestampToTime () {
      const date = new Date()
      const Y = date.getFullYear() + '-';
      const M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      const D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
      const h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
      const m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
      const s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
      return Y+M+D+h+m+s;
    }
    // 整理上传参数
    mergeParams (et, purl, ed) {
        const commonDefaultParams = {
            ct: '',
            purl: '',
            ei: '',
            et: 'PV',
            en: '',
            el: 'INFO',
            ed: {}
        }
        return Object.assign({}, commonDefaultParams, {
            purl: this.moduleName + purl,
            ei: this.dotTypeMap[et],
            ct: SelfLog.timestampToTime(),
            et,
            ed
        })
    }
    // 埋点上报
    log (et, purl, ed = {}) {
        if (isApp()) {
            const uploadJson = this.mergeParams(et, purl, ed)
            console.log(uploadJson)
            sendDotMessageToApp(uploadJson)
        }
    }
    // pv点
    pv (routerName, extraData) {
        this.log ('PV', routerName, extraData)
    }
    // 性能点
    perf (routerName, extraData) {
        this.log ('Perf', routerName, extraData)
    }
}

export default new SelfLog()

