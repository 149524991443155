import { isApp, isAndroid } from '@/utils/common'
const CryptoJS = require('crypto-js')

const keyStr = (function () {
  if (!isApp()) {
    return process.env.VUE_APP_H5_APPSECRET
  }
  if (isAndroid()) {
    return process.env.VUE_APP_ANDROID_APPSECRET
  }
  return process.env.VUE_APP_IOS_APPSECRET
})()
/**
 * 加密
 * @param message
 * @returns {string}
 */
export const encrypt = (message) => {
  var keyHex = CryptoJS.enc.Utf8.parse(keyStr)
  var encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
}
/**
 * 解密
 * @param ciphertext
 * @returns {string}
 */
export const decrypt = (ciphertext) => {
  var keyHex = CryptoJS.enc.Utf8.parse(keyStr)
  var decrypted = CryptoJS.DES.decrypt({
    ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
  }, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  var result_value = decrypted.toString(CryptoJS.enc.Utf8)
  return result_value
}
