import hyraxios from '@/hyraxios'
import axios from 'axios';
//  合格投资者确认
export function qualifiedConfirm(params) {
	return hyraxios('/v1/customer/qualified-confirm', params)
}
// 产品列表
export function productList(params) {
	return hyraxios('/v1/product/product-list', params)
}
// 产品详情
export function productDetail(params) {
	return hyraxios('/v1/product/product-detail', params)
}

// 预约咨询提交
export function consultationSubmit(params) {
	return hyraxios('/v1/consultation/consultation-submit', params)
}

// 预约咨询列表
export function consultationList(params) {
	return hyraxios('/v1/consultation/consultation-list', params)
}

// 查看私募产品状态提交
export function viewPrivateProductStatusSubmit(params) {
	return hyraxios('/v1/customer/view-private-product-status-submit', params)
}

// 高端理财 推荐列表
export function productRecommend(params) {
	return hyraxios('/v1/product/product-recommend', params)
}

// 获取App包下载地址json
export function getAppDownloadUrl() {
	return {
		data: {
			code: '200',
			data: {
				ios: 'https://apps.apple.com/app/id1574301090',
				// android: 'https://event.yirongtk.com/uploads2/app/tuike/kyjj.apk',
        android: 'http://app.kunyuanfund.com:8070/agate/api/v1/common/download?id=tjSYeq7VHxeKA2FxTGFhKDEPVATAG0qJdAYTOEMKVOs%3D',
			},
		},
	}
}

// 邀请码回显
export function getPeInviteCode(params) {
	return hyraxios('/v1/customer/get-pe-invite-code', params)
}

//  获取隐私协议内容
export function getUserPrivacyAgrement(params) {
  // /kyf-pe-api
  return axios.get('/kyf-customer-api/customer/protocol/privacy-policy/protocol-article?device-no=xxx-xxx-xxx&device-timestamp=1718094465000', params)
}
