import Vue from 'vue'
import App from './App.vue'
import { sync } from 'vuex-router-sync'
import router from './router'
import store from './store'
import './utils/iosReload.js'
import 'mand-mobile/components/_style/global.styl'
import 'normalize.css'
import '@/utils/backspaceReturn'
import '@/assets/icons/index'
import './mandMobile'
import './global.styl'
import './utils/directive'
/* import './filter' */
import VueWechatTitle from 'vue-wechat-title'
import { sentryInit } from '@/utils/sentryInit'
import SelfLogIns from '@/utils/selfLog'

// 初始化sentry
sentryInit(Vue, router)

Vue.use(VueWechatTitle)
Vue.config.productionTip = false
Vue.prototype.$selfLog = SelfLogIns
sync(store, router)

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
