export default {
  name: 'agreement',
  path: '/agreement',
  component: () => import(/* webpackChunkName: "mine" */ '@/views/layouts/blank.vue'),
  children: [
    {
      path: 'riskReveal',
      name: 'riskReveal',
      component: () => import(/* webpackChunkName: 'agreementRiskReveal' */ '@/views/agreement/riskReveal'),
      meta: {
        title: '风险揭示书及高风险产品特别揭示书'
      }
    },
    {
      path: 'selfService',
      name: 'selfService',
      component: () => import(/* webpackChunkName: 'agreementSelfService' */ '@/views/agreement/selfService'),
      meta: {
        title: '网上自助服务协议'
      }
    },
    {
      path: 'autoTransfer',
      name: 'autoTransfer',
      component: () => import(/* webpackChunkName: 'autoTransfer' */ '@/views/agreement/autoTransfer'),
      meta: {
        title: '自动转账扣款授权书'
      }
    },
    {
      path: 'quickPayment',
      name: 'quickPayment',
      component: () => import(/* webpackChunkName: 'quickPayment' */ '@/views/agreement/quickPayment'),
      meta: {
        title: '快捷支付协议'
      }
    },
    {
      path: 'quickPaymentICBC',
      name: 'quickPaymentICBC',
      component: () => import(/* webpackChunkName: 'quickPaymentICBC' */ '@/views/agreement/quickPaymentICBC'),
      meta: {
        title: '工商银行快捷支付协议'
      }
    },
    {
      path: 'quickPaymentABC',
      name: 'quickPaymentABC',
      component: () => import(/* webpackChunkName: 'quickPaymentICBC' */ '@/views/agreement/quickPaymentABC'),
      meta: {
        title: '农业银行快捷支付协议'
      }
    },
    {
      path: 'quickPaymentCBC',
      name: 'quickPaymentCBC',
      component: () => import(/* webpackChunkName: 'quickPaymentICBC' */ '@/views/agreement/quickPaymentCBC'),
      meta: {
        title: '建设银行快捷支付协议'
      }
    },
    {
      path: 'quickPaymentBOCOM',
      name: 'quickPaymentBOCOM',
      component: () => import(/* webpackChunkName: 'quickPaymentICBC' */ '@/views/agreement/quickPaymentBOCOM'),
      meta: {
        title: '交通银行快捷支付协议'
      }
    },
    {
      path: 'userPrivacy',
      name: 'userPrivacy',
      component: () => import(/* webpackChunkName: 'agreementUserPrivacy' */ '@/views/agreement/userPrivacy'),
      meta: {
        title: '坤元基金用户隐私保护政策'
      }
    },
    {
      path: 'allinpay',
      name: 'allinpay',
      component: () => import(/* webpackChunkName: 'agreementUserPrivacy' */ '@/views/agreement/allinpay'),
      meta: {
        title: '补充通联协议'
      }
    },
    {
      path: 'investorsEquity',
      name: 'investorsEquity',
      component: () => import(/* webpackChunkName: 'agreementUserPrivacy' */ '@/views/agreement/investorsEquity'),
      meta: {
        title: '投资者权益须知',
      },
    },
    {
      path: 'tradingServices',
      name: 'tradingServices',
      component: () => import(/* webpackChunkName: 'agreementUserPrivacy' */ '@/views/agreement/tradingServices'),
      meta: {
        title: '网上交易服务协议',
      },
    },
  ]
}
