export default {
  name: 'fund',
  path: '/fund',
  component: () =>
    import(/* webpackChunkName: "fund" */ '@/views/layouts/blank.vue'),
  children: [
    {
      path: 'index',
      name: 'index',
      component: () =>
        import(
          /* webpackChunkName: 'fund' */ '@/views/fund/index'
        ),
      meta: {
        title: '中加纯债债券',
      },
    },
  ],
}
