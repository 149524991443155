import Vue from 'vue'
import Loading from './loading.vue'

const LoadingConstructor = Vue.extend(Loading)
let instance

export default {

  open () {
    if (!instance) {
      instance = new LoadingConstructor({
        el: document.createElement('div')
      })
    }
    if (instance.isShow) return
    document.body.appendChild(instance.$el)

    Vue.nextTick(() => {
      instance.open()
      instance.isShow = true
    })
  },
  close () {
    if (instance) {
      Vue.nextTick(() => {
        instance.close()
        instance.isShow = false
      })
    }
  }
}
