<template>
  <div id="app">
    <md-dialog title="测试弹出框" :closable="true" v-model="showTestDialog">
      <ul class="zi-ul">
        <li>
          <a class="c-list" @click="refresh">刷新</a>
        </li>
        <li v-if="isApp">
          <a class="c-list" @click="switchTest('1')">切换测试1</a>
        </li>
        <li v-if="isApp">
          <a class="c-list" @click="switchTest('2')">切换测试2</a>
        </li>
        <li v-if="isApp">
          <a class="c-list" @click="switchAddress">切换地址</a>
        </li>
        <li>
          <a class="c-list" @click="goTest">去测试页面</a>
        </li>
      </ul>
    </md-dialog>
    <template v-if="showRoute">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </template>
    <md-dialog :closable="true" v-model="basicDialog.open" :btns="basicDialog.btns" title="切换地址">
      <!--:btns="basicDialog.btns"-->
      <md-input-item
        type="text"
        v-model="address"
        placeholder="请输入地址"
        @focus="addressError = ''"
        :error="addressError"
        clearable
      ></md-input-item>
    </md-dialog>
  </div>
</template>
<script>
import { isApp } from '@/utils/common'
const requestReg = /^(\d{1,3}\.){3}\d{1,3}:\d{4}$/
// 可拖动小圆点插件
export default {
  name: 'app',
  data() {
    return {
      // 是否在app中
      isApp: isApp(),
      // 展示路由
      showRoute: false,
      // 测试切换
      basicDialog: {
        open: false,
        btns: [
          {
            text: '取消'
          },
          {
            text: '确认操作',
            handler: this.switchAddressOk
          }
        ]
      },
      // 展示测试框
      showTestDialog: false,
      // 地址
      address: '',
      // 地址错误信息
      addressError: ''
    }
  },
  computed: {},
  methods: {
    // 刷新
    refresh() {
      window.location.reload()
    },
    // 去测试
    goTest() {
      this.$router.push({
        path: '/test'
      })
    },
    // 切换地址
    switchAddressOk() {
      const {
        address,
        route: { fullPath },
        isApp
      } = this
      if (!isApp) {
        this.$toast.info('此功能只限app中使用')
        return
      }
      if (!requestReg.test(address)) {
        this.addressError = '请输入正确的切换地址'
        return
      }
      location.replace(`//${address}${fullPath}`)
    },
    // 切换测试环境
    switchTest(testType) {
      const test1 = '1'
      const test2 = '2'
      const {
        route: { fullPath }
      } = this
      const locationHref = location.href
      let urlBefore
      switch (testType) {
        case test1:
          if (locationHref.match('http://testevent.hengyirong.com/kunyuan/')) {
            return this.$toast.info('您当前就在正式测试上')
          }
          urlBefore = 'http://testevent.hengyirong.com/kunyuan/'
          break
        case test2:
          if (locationHref.match('http://testevent.hengyirong.com/kunyuan2/')) {
            return this.$toast.info('您当前就在自定义测试上')
          }
          urlBefore = 'http://testevent.hengyirong.com/kunyuan2/'
          break
        default:
          break
      }
      location.replace(`${urlBefore}${fullPath}`)
    },
    // 切换地址
    switchAddress() {
      const { basicDialog } = this
      if (this.$refs.devTool) {
        this.$refs.devTool.close()
      }
      this.address = ''
      this.addressError = ''
      this.basicDialog = {
        ...basicDialog,
        open: true
      }
    }
  },
  async created() {
    this.showRoute = true
    if (process.env.VUE_APP_ONLINE_ENV === 'false') {
      window.showTest = () => {
        this.showTestDialog = true
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
#app {
  font-family 'PingFangSC', 'Avenir', Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color color-text-base
  height 100%
}
.zi-ul {
  padding-top 10px
  li {
    height 68px
    line-height 68px
    text-align center
    .c-list {
      color color-text-base
      font-size 30px
      text-decoration underline
      font-weight 300px
      cursor pointer
    }
  }
}
</style>
