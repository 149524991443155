import Vue from 'vue'
import NoData from '@/components/NoData'
import NoMoreData from '@/components/NoMoreData'
import Loading from '@/components/Loading'
import {
	Toast,
	DropMenu,
	InputItem,
	Field,
	Button,
	TabBar,
	Captcha,
	Icon,
	ActivityIndicator,
	Dialog,
	CellItem,
	ImageReader,
	Tag,
	Radio,
	RadioGroup,
	Amount,
	TextareaItem,
	FieldItem,
	Picker,
	Agree,
	DatePicker,
	RadioList,
	Swiper,
	SwiperItem,
	Tabs,
	TabPane,
	Popup,
	PopupTitleBar,
	Skeleton,
	Switch,
	ScrollView,
	ScrollViewRefresh,
	ScrollViewMore,
} from 'mand-mobile'
Vue.component('NoData', NoData)
Vue.component('NoMoreData', NoMoreData)
Vue.component(InputItem.name, InputItem)
Vue.component(Field.name, Field)
Vue.component(Button.name, Button)
Vue.component(Captcha.name, Captcha)
Vue.component(TabBar.name, TabBar)
Vue.component(Icon.name, Icon)
Vue.component(ActivityIndicator.name, ActivityIndicator)
Vue.component(Dialog.name, Dialog)
Vue.component(CellItem.name, CellItem)
Vue.component(ImageReader.name, ImageReader)
Vue.component(Tag.name, Tag)
Vue.component(Radio.name, Radio)
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(Amount.name, Amount)
Vue.component(TextareaItem.name, TextareaItem)
Vue.component(FieldItem.name, FieldItem)
Vue.component(Picker.name, Picker)
Vue.component(Agree.name, Agree)
Vue.component(DatePicker.name, DatePicker)
Vue.component(Swiper.name, Swiper)
Vue.component(SwiperItem.name, SwiperItem)
Vue.component(Tabs.name, Tabs)
Vue.component(Tabs.name, Tabs)
Vue.component(TabPane.name, TabPane)
Vue.component(Popup.name, Popup)
Vue.component(PopupTitleBar.name, PopupTitleBar)
Vue.component(Skeleton.name, Skeleton)
Vue.component(Switch.name, Switch)
Vue.component(RadioList.name, RadioList)
Vue.component(ScrollView.name, ScrollView)
Vue.component(ScrollViewRefresh.name, ScrollViewRefresh)
Vue.component(ScrollViewMore.name, ScrollViewMore)
Vue.component(DropMenu.name, DropMenu)

Vue.prototype.$toast = Toast
Vue.prototype.$dialog = Dialog
Vue.prototype.$loading = Loading
