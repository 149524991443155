export default {
	name: 'discovery',
	path: '/discovery',
	component: () => import(/* webpackChunkName: "discovery" */ '@/views/layouts/blank.vue'),
	redirect: '/discovery/list',
	children: [
		{
			path: 'list',
			name: 'discovery-list',
			component: () => import(/* webpackChunkName: "discovery" */ '@/views/discovery/list'),
			meta: {
				title: '发现',
				keepAlive: true,
			},
		},
		{
			path: 'detail/:id',
			name: 'discovery-detail',
			component: () => import(/* webpackChunkName: "discovery" */ '@/views/discovery/detail'),
			meta: {
				title: '资讯详情',
			},
		},
	],
}
