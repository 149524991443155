export default {
  name: 'product',
  path: '/product',
  component: () => import(/* webpackChunkName: "product" */ '@/views/layouts/blank.vue'),
  children: [
    {
      path: 'index',
      name: 'productIndex',
      component: () => import(/* webpackChunkName: "product" */ '@/views/product/index'),
      meta: {
        title: '高端理财',
        keepAlive: true,
      },
    },
    {
      path: 'search',
      name: 'searchList',
      component: () => import('@/views/product/search'),
    },
    {
      path: 'detail',
      name: 'productDetail',
      component: () => import(/* webpackChunkName: "product" */ '@/views/product/productDetail'),
      meta: {
        title: '产品详情',
      },
    },
    {
      path: 'list',
      name: 'productList',
      component: () => import(/* webpackChunkName: "product" */ '@//views/product/productList'),
      meta: {
        title: '高端理财',
      },
    },
    {
      path: 'riskDisclosure',
      name: 'riskDisclosure',
      component: () => import(/* webpackChunkName: "product" */ '@//views/product/riskDisclosure'),
      meta: {
        title: '风险揭示书',
      },
    },
    {
      path: 'qualifiedInvestors',
      name: 'qualifiedInvestors',
      component: () => import(/* webpackChunkName: "product" */ '@//views/product/qualifiedInvestors'),
      meta: {
        title: '合格投资者认定',
      }
    },
    {
      path: 'confirmingOrder',
      name: 'confirmingOrder',
      component: () => import(/* webpackChunkName: "product" */ '@//views/product/confirmingOrder'),
      meta: {
        title: '适当性结果确认书',
      },
    },
    {
      path: 'inviteCode',
      name: 'InviteCode',
      component: () => import(/* webpackChunkName: "product" */ '@/views/product/inviteCode'),
      meta: {
        title: '填写邀请码',
      },
    },
    {
      path: 'subscribeList',
      name: 'subscribeList',
      component: () => import(/* webpackChunkName: "product" */ '@/views/product/subscribeList'),
      meta: {
        title: '预约记录',
      },
    },
    {
      path: 'promotion',
      name: 'promotion',
      component: () => import(/* webpackChunkName: "product" */ '@/views/product/promotion'),
      meta: {
        title: '推广',
      },
    },
    {
      path: 'promotion-result',
      name: 'promotion-result',
      component: () => import(/* webpackChunkName: "product" */ '@/views/product/promotionResult'),
      meta: {
        title: '结果',
      },
    },
  ],
}
