/**
 *
 * 禁止点击backspace 返回上一页
 */
window.onload = function () {
  document.getElementsByTagName('body')[0].onkeydown = function () {
    if (parseInt(event.keyCode) === 8) { // 判断按键为backSpace键
      // 获取按键按下时光标做指向的element
      const elem = event.srcElement || event.currentTarget
      // 判断是否需要阻止按下键盘的事件默认传递
      const name = elem.nodeName
      if (name !== 'INPUT' && name !== 'TEXTAREA') {
        return _stopIt(event)
      }
      if (name === 'INPUT' && (Boolean(elem.readOnly) === true || Boolean(elem.disabled) === true)) {
        return _stopIt(event)
      }
    }
  }
}

function _stopIt (e) {
  if (e.returnValue) {
    e.returnValue = false
  }
  if (e.preventDefault) {
    e.preventDefault()
  }

  return false
}
