import Vue from 'vue'
import store from './store'
import hyraxios from '@hc/hyraxios'
import * as api from './api'
import { Toast } from 'mand-mobile'
import { getToken, getJwtToken, removeToken, removeJwtToken, setShortTime, getSessionStorage } from '@/utils/auth'
import { JZ_TOKEN } from '@/utils/common'
import { encrypt, decrypt } from '@/utils/crypto'
/* import md5 from 'js-md5' */
/**
 * 请求失效
 */
const error401 = async function () {
  /*  if (isApp()) {
     await sendAppMessage({ action: 'openWin', winName: 'login' })
   }
   if (store.state.route.name === 'Login') {
     return
   } */
  store.dispatch('user/login', { token: getSessionStorage(JZ_TOKEN) })
  // 删除token
  removeToken()
  removeJwtToken()
}
hyraxios.init({
  // 响应超时时间
  timeout: process.env.VUE_APP_API_TIMEOUT || 10000,
  // 加密串
  appSecret: process.env.VUE_APP_H5_APPSECRET,
  // 登录token
  token: getToken() || '',
  // 获取初始值
  requestSuccCallback: async config => {
    if (config.url.indexOf('kyf-news-api') > -1) {
      config.baseURL = process.env.VUE_APP_NEWS_SERVER_TARGET
    } else if (config.url.indexOf('kyf-pe-api') > -1 || config.url.indexOf('kyf-activity-api') > -1) {
      config.baseURL = process.env.VUE_APP_PE_TARGET
    }
    config.headers.jwt = getJwtToken()
    return config
  },

  // 正常返回拦截
  responseSuccCallback: function (data, headers) {
    if (process.env.VUE_APP_CRYPTO === 'true' && typeof data.data === 'string' && data.data) {
      data.data = JSON.parse(decrypt(data.data))
    }
    if (data && data.code) {
      const code = parseInt(data.code)
      if (code === 401) {
        error401()
      }
      if (data.subcode === '4001' && headers['x-timestamp']) {
        setShortTime(headers['x-timestamp'] - Math.round(Date.now() / 1000))
        location.reload()
      }
      return data
    }
    Toast.info('服务器繁忙，请稍后重试')
    return Promise.reject(new Error('接口数据格式错误'))
  },
  // 失败返回拦截
  responseFailCallback: function (error) {
    let errmsg = ''
    if (error.response) {
      if (error.response.status >= 500 && error.response.status <= 599) {
        errmsg = '服务器繁忙，请稍后重试'
      } else if (error.response.status === 404) {
        errmsg = '服务不存在'
      } else {
        errmsg = '网络繁忙，请稍后重试'
      }
    } else {
      if (error.message.indexOf('timeout') > -1) {
        errmsg = '网络超时，请稍后重试'
      } else if (error.message.indexOf('Network Error') > -1) {
        errmsg = '网络连接异常，请稍后重试'
      } else {
        errmsg = '网络繁忙，请稍后重试'
      }
    }
    Toast.failed(errmsg)
    return Promise.reject(new Error(errmsg))
  },
  // baseurl
  baseURL: process.env.VUE_APP_TARGET,
  // appId
  appId: 'h5',
  // 客户端信息
  clientInfo: 'h5',
  // 版本
  ver: '1.1',
  // 版本
  clientVersion: '4.0.1'
})
// 在组件内可以使用this.$axios, this.$api来调用相应的方法和接口
Vue.use(hyraxios, api)
const hyraxios_self = (url, data = {}, from, config) => {
  if (process.env.VUE_APP_CRYPTO === 'true') {
    const dataStr = JSON.stringify(data)
    const needData = { data: encrypt(dataStr) }
    return hyraxios(url, needData, from, config)
  } else {
    return hyraxios(url, data, from, config)
  }
}
export default hyraxios_self
export { hyraxios }
