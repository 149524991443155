import hyraxios from '@/hyraxios'
const ACTIVITY_API = process.env.VUE_APP_ACTIVITY_API
// Token方式用户登录
export function loginByToken(params) {
  return hyraxios('/v1/customer/login-by-token', params)
}
// 检测登录状态
export function userDetail(params) {
  return hyraxios('/v1/customer/user-detail', params)
}

// 获取手机验证码
export function sendSmsCode(params) {
  return hyraxios('/v1/customer/send-sms-code', params)
}
// 邀请注册
export function inviteRegister(params) {
  return hyraxios('/v1/customer/invite-register', params)
}
// 查询用户类型
export function userBelong(params) {
  return hyraxios(
    `${ACTIVITY_API}/kyf-activity-api/hrhb/query/userBelong`,
    params
  )
}
