export default {
  name: 'mine',
  path: '/mine',
  component: () =>
    import(/* webpackChunkName: "mine" */ '@/views/layouts/blank.vue'),
  children: [
    {
      path: 'verifyFace',
      name: 'verifyFace',
      component: () =>
        import(
          /* webpackChunkName: 'mineVerifyFace' */ '@/views/mine/verifyFace'
        ),
      meta: {
        title: '人脸识别',
      },
    },
  ],
}
