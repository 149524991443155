import hyraxios from '@/hyraxios'

// 栏目列表查询接口
export function querySectionList(params) {
	return hyraxios('/kyf-news-api/h5/querySectionList', params)
}

// 资讯列表查询接口
export function queryNewsList(params) {
	return hyraxios('/kyf-news-api/h5/queryNewsList', params)
}

// 咨询详情
export function queryNewsDetail(params) {
	return hyraxios('/kyf-news-api/h5/queryNewsDetail', params)
}

// 查询运营位位置banner
export function queryBannerList(params) {
	return hyraxios('/kyf-news-api/h5/queryBannerList', params)
}

// 产品详情中的按钮
export function btnOrderAccess(params) {
	return hyraxios('/pe/kyf-pe-api/order/process', params)
}
// return hyraxios('/pe/kyf-pe-api/test/webtest', params)

// 微信升级公告
export function consultQuery(params) {
	return hyraxios('/kyf-news-api/consult/query?platform_type=' + params.platform_type)
}
