import { setCookie, getCookie, delCookie } from '@hc/core'

const TokenKey = 'x-access-token'
const ShortTime = 'ShortTime'
const JWT_TOKEN = 'jwt'

export function getToken() {
	return getCookie(TokenKey) === null ? '' : getCookie(TokenKey)
}

export function setToken(token) {
	setCookie(TokenKey, token, { expiresIn: '1h', path: '/' })
}
export function removeToken() {
	delCookie(TokenKey, { path: '/' })
}

export function getJwtToken() {
	return getCookie(JWT_TOKEN) === null ? '' : getCookie(JWT_TOKEN)
}

export function setJwtToken(token) {
	setCookie(JWT_TOKEN, token, { expiresIn: '1h', path: '/' })
}

export function removeJwtToken(token) {
	delCookie(JWT_TOKEN, { path: '/' })
}

export function setShortTime(token) {
	setCookie(ShortTime, token, { expiresIn: '30d', path: '/' })
}
export function getShortTime() {
	return getCookie(ShortTime) === null ? '' : getCookie(ShortTime)
}
/**
 * 设置本地缓存
 * @param key
 * @param val
 */
export function setSessionStorage(key, val) {
	if (typeof key === 'object') {
		for (const item in key) {
			sessionStorage.setItem(item, JSON.stringify(key[item]))
		}
	} else {
		sessionStorage.setItem(key, JSON.stringify(val))
	}
}

/**
 * 获取本地缓存
 * @param key
 */
export function getSessionStorage(key) {
	return JSON.parse(sessionStorage.getItem(key)) || ''
}

/**
 * 清楚本地缓存
 * @param key
 */
export function removeSessionStorage(key) {
	sessionStorage.removeItem(key)
}

/**
 *
 */
export function clearSessionStorage() {
	sessionStorage.clear()
}
