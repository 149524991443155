export default {
	name: 'result',
	path: '/result',
	component: () => import(/* webpackChunkName: "mine" */ '@/views/layouts/blank.vue'),
	children: [
		{
			path: 'appointmentSuccess',
			name: 'appointment',
			component: () => import(/* webpackChunkName: "appointmentSuccess" */ '@/views/product/appointmentSuccess'),
			meta: {
				title: '预约成功',
			},
		},
		{
			path: 'paySuccess',
			name: 'PaySuccess',
			component: () => import(/* webpackChunkName: "paySuccess" */ '@/views/product/paySuccess'),
			meta: {
				title: '支付成功',
			},
		},
		{
			path: 'paySuccess1',
			name: 'PaySuccess1',
			component: () => import(/* webpackChunkName: "paySuccess1" */ '@/views/product/paySuccess1'),
			meta: {
				title: '支付成功1',
			},
		},
	],
}
