import { getToken, setToken, removeToken, removeJwtToken, getShortTime, setJwtToken } from '@/utils/auth'
import api from '@/api'
import { hyraxios } from '@/hyraxios'
import Loading from '@/components/Loading'
import '@/utils/gt'
import router from '@/router'
import { Toast } from 'mand-mobile'
const state = {
	// 登录凭证
	accessToken: getToken(),
	// 时间差
	shortTime: getShortTime() ? parseInt(getShortTime()) : 0,
	userInfo: {},
}

const actions = {
	// 登录
	async login ({ state, commit, rootState }, params) {
		try {
			const { code, subcode, msg, submsg, data } = await api.loginByToken(params)
			if (code === '200') {
				commit('LOGIN_SUCCESS', { ...data })
				return { code, subcode, msg, submsg, data }
			} else {
				commit('CHECK_LOGIN_FAILURE')
				// h5环境去错误结果页
				setTimeout(() => {
					router.replace({ path: '/error500', query: { msg: msg } })
				}, 500)
				return { code, subcode, msg, submsg, data }
			}
		} catch (err) {
			debuglog(err) // eslint-disable-line
		} finally {
		}
	},
	// 判断是否登录
	async userDetail ({ state, commit }, params) {
		try {
			Loading.open()
			const res = await api.userDetail(params)
			const { code, subcode, msg, submsg, data } = res
			if (code === '200') {
				commit('UPDATE_QUALIFED_CONFIR', { ...data })
			}
			return { code, subcode, msg, submsg, data }
			// commit('CHECK_LOGIN_FAILURE')
		} catch (err) {
			debuglog(err) // eslint-disable-line
		} finally {
			Loading.close()
		}
	},
	// 合格投资者确认
	async qualifiedConfirm ({ state, commit }, params) {
		try {
			Loading.open()
			const res = await api.qualifiedConfirm(params)
			const { code, subcode, msg, submsg, data } = res
			if (code === '200') {
				commit('UPDATE_QUALIFED_CONFIR', { ...data, hasConfirmQualified: true })
				return { code, subcode, msg, submsg, data }
			}
		} catch (err) {
			debuglog(err) // eslint-disable-line
		} finally {
			Loading.close()
		}
	},
	// 发送验证码
	async smsCode ({ state, commit, rootState }, params) {
		try {
			Loading.open()
			const { code, msg, data, submsg } = await api.getcode(params)
			if (code === '200' || code === '406') {
				return { data, code }
			} else {
				Toast.info(submsg || msg)
				return { code, msg, data, submsg }
			}
		} catch (err) {
			debuglog(err) // eslint-disable-line
		} finally {
			Loading.close()
		}
	},
	// 校验验证码
	async validateSmsCode ({ state, commit, rootState }, params) {
		try {
			Loading.open()
			const { code, msg, submsg, data } = await api.validateSmsCode(params)
			if (code === '200') {
				return { data, code }
			} else {
				Toast.info(submsg || msg)
			}
		} catch (err) {
			debuglog(err) // eslint-disable-line
		} finally {
			Loading.close()
		}
	},
}
const mutations = {
	// 登录成功
	LOGIN_SUCCESS (state, payload) {
		if (payload.id_number) {
			localStorage.setItem('id_number', payload.id_number)
		}
		const { accessToken } = payload
		setToken(accessToken)
		payload.jwtToken && setJwtToken(payload.jwtToken)
		state.accessToken = accessToken
		state.userInfo = { ...payload }
		hyraxios.setConfig('token', accessToken)
	},
	// 核对登录失败
	CHECK_LOGIN_FAILURE (state) {
		state.accessToken = ''
		removeToken()
		removeJwtToken()
		localStorage.removeItem('id_number')
		state.userInfo = {}
		hyraxios.setConfig('token', '')
	},
	UPDATE_QUALIFED_CONFIR (state, action) {
		state.userInfo = { ...state.userInfo, ...action }
	},
}
export default {
	namespaced: true,
	state,
	actions,
	mutations,
}
