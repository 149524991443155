/**
 * 修改苹果手机返回页面不刷新的问题
 * 选择返回上一页时触发的事件：pageshow
 */
(function () {
  // 返回显示的问题
  let isPageHide = false
  window.addEventListener('pageshow', function () {
    if (isPageHide) {
      // window.location.reload()
    }
  })
  window.addEventListener('pagehide', function () {
    isPageHide = true
  })

  // 兼容华为新窗口打开的手机
  document.addEventListener('visibilitychange', function () {
    const type = document.visibilityState
    switch (type) {
      case 'hidden':
        isPageHide = true
        break
      case 'visible':
        // window.location.reload()
        break
      default:
        break
    }
  })
})()
