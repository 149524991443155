import { getUUID, isApp } from './common'
import * as Sentry from '@sentry/vue'

/**
 * sentry埋点上报
 * @param type pv/init/ub
 * @param name 埋点名称
 * @param selfData 自定义数据
 * @param params 支持扩展数据，但是必须符合Sentry.captureEvent的入参
 */
const sentryLog = (type, name = '', selfData = {}, ...params) => {
    if (!type) throw new Error('请检查是否传入埋点类型')

    const module = 'pe'
    const logTypeEnum = {
        pv: {
            messagePad: 'KY_H5_PV',
            tags: {
                KY_H5_Event_Type: 'PV',
                KY_H5_Port_Name: `${module}_${name}`,
                ['KY_H5_PV_' + `${module}_${name}`]: 1
            }
        },
        ub: {
            messagePad: 'KY_H5_UB',
            tags: {
                KY_H5_Event_Type: 'UB',
                KY_H5_Port_Name: `${module}_${name}`,
                ['KY_H5_UB_' + `${module}_${name}`]: 1
            }
        },
        init: {
            messagePad: 'KY_H5_INIT',
            tags: {
                KY_H5_Event_Type: 'INIT',
                KY_H5_INIT: 1
            }
        }
    }

    const message = logTypeEnum[type].messagePad + (name && `_${module}_${name}`)
    const tags = logTypeEnum[type].tags

    if (isApp()) {
      Sentry.captureEvent({
        message,
        tags,
        level: 'log',
        contexts: {
          extraData: {
            uuid: getUUID(),
            ...selfData
          }
        },
        ...params
      })
    }
}

export {
    sentryLog
}
