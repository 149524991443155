<!-- NoData -->
<template>
  <div class="no-data">
    <div :class="{'no-data-img':true,'info-no-data-img':infoNoData}"></div>
    <slot>
      <p class="text">{{desc}}</p>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    desc: {
      type: String,
      default: '暂无记录'
    },
    infoNoData: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  }
}

</script>
<style lang='stylus' scoped>
.no-data {
  overflow: hidden;
  position: relative;
  z-index: 1;

  .no-data-img {
    width: 262px;
    height: 244px;
    margin: 149px auto 0;
    background-image: url('~@/assets/images/NoData1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.info-no-data-img {
      width: 411px;
      height: 250px;
      background-image: url('~@/assets/images/info_no_data.png');
    }
  }

  .text {
    margin-top: 14px;
    text-align: center;
    color: #999999;
    font-size: 28px;
  }
}
</style>
